<template>
  <div class="main-layer">
    <preloader v-if="!isInit"/>
    <div class="accounts" v-else>
      <div class="accounts__title">
        <template v-if="!showCommissionsPage">
          {{ $t('advance_balances.title') }}
          <common-info-modal
            :description="$t('info_modals.advance_balances.description')"
            style="margin-left: 10px"
          />
        </template>
        <template v-else>
          {{ $t('advance_balances.commissions.banner.title') }}
        </template>
      </div>

      <div class="accounts__subtitle">
        {{ $t('advance_balances.description') }}
      </div>
      <return-button
        v-if="showCommissionsPage"
        @click="showCommissionsPage = false"
      />

      <div>
        <!--    важно - требование бизнеса - принимаем что массив accounts всегда сожержит всего ОДНУ запись -->
        <!--    оставляем название класса accounts__list несмотря на то что используем всего один аккаунт -->
        <div
          v-if="!showCommissionsPage"
          class="accounts__header-and-body"
        >
          <zero-state
            v-if="false"
            :meta="zeroStateMeta"
            :background-transparent="false"
            @open-replenish-request-modal="openReplenishRequestModal"
          />
          <template v-else-if="hasAccounts">
            <div class="account-header">
              <AccountCard
                class="account-card"
                :card="firstAccount"
                :show-commissions-page.sync="showCommissionsPage"
                @open-replenish-request-modal="openReplenishRequestModal"
              />
              <MyCommissionsBanner :show-commissions-page.sync="showCommissionsPage" />
            </div>

            <History
              class="account-history"
              :show-zero-state.sync="showZeroState"
            />
          </template>
        </div>
        <div
          v-else
          class="accounts__header-and-body"
        >
          <div class="commissions">
            <div class="commissions__list-wrapper">
              <commissions-list
                :account="accounts[0]"
                style="margin-bottom: 10px"
              />
            </div>
            <div v-if="contactUsBannerShow" class="commissions__contact-us">
              <svg
                class="close-btn"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                @click="contactUsBannerShow = false"
              >
                <path
                  d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
                  :fill="themeStatus !== 'dark' ? '#343A40' : '#FFFFFF'"
                />
              </svg>
              <div class="info-wrapper">
                <div class="title">
                  {{ $t('advance_balances.commissions.contact-us-banner.title') }}
                </div>
                <div class="description">
                  {{ $t('advance_balances.commissions.contact-us-banner.description') }}
                </div>
              </div>
              <div class="action" @click="goToTelegram">
                <base-button
                  id="intercom_custom_activator"
                  type="primary"
                  :label="$t('advance_balances.btn-label.commissions.contact-us-banner.contact-us')"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChoiceStepModal/>
      <DepositRequestModal/>
      <AccountModalTransfer/>
      <transition name="slide-fade">
        <transaction-info v-if="isInfoOpened" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AccountsListHeader from "/public/components/elements/accounts/AccountsListHeader";
import Account from "/public/components/elements/accounts/Account";
import AccountDataFilterByType from "/public/components/elements/accounts/AccountDataFilterByType";
import AccountData from "/public/components/elements/accounts/AccountData";
import EmptyTable from "/public/components/elements/EmptyTable";
import TransactionsHistoryListHeader from "../components/elements/accounts/TransactionsHistoryListHeader";
import Transaction from "/public/components/elements/accounts/Transaction";
import DepositRequestModal from '/public/components/modals/accounts/DepositRequestModal';
import ChoiceStepModal from '/public/components/modals/accounts/ChoiceStepModal';
import AccountModalTransfer from '/public/components/modals/accounts/AccountModalTransfer';
import AccountsDataListHeader from "../components/elements/accounts/AccountsDataListHeader";
import AccountDataFilterByDate from "../components/elements/accounts/AccountDataFilterByDate";
import AccountsTariffsHeader from "../components/elements/accounts/AccountsTariffsHeader";
import Tariffs from "../components/elements/accounts/Tariffs";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import AccountsDownCommission from "../components/elements/accounts/AccountsDownCommission";
import getUserContent from "../graphQl/queries/getUserContent";

import TransactionInfo from "@/public/components/elements/transactions/TransactionInfo.vue";
import { isAlbertCrypto } from "@/config";

export default {
    name: 'AccountsPage',
    title: 'advance_balances.title',
    rulesAlias: 'advanced_balance',
    components: {
        TransactionInfo,
        AccountsDownCommission,
        Preloader,
        Tariffs,
        AccountsTariffsHeader,
        AccountsListHeader,
        Account,
        AccountCard: () => import(/* webpackChunkName: "public/components/elements/accounts/AccountCard" */ '/public/components/elements/accounts/AccountCard'),
        AccountCardOld: () => import(/* webpackChunkName: "public/components/elements/accounts/AccountCard" */ '/public/components/elements/accounts/AccountCardOld'),
        AccountDataFilterByType,
        AccountData,
        AccountsDataListHeader,
        EmptyTable,
        TransactionsHistoryListHeader,
        Transaction,
        DepositRequestModal,
        ChoiceStepModal,
        AccountModalTransfer,
        AccountDataFilterByDate,
        MyCommissionsBanner: () => import(/* webpackChunkName: "public/components/elements/accounts/Commissions/MyCommissionsBanner" */ '/public/components/elements/accounts/Commissions/MyCommissionsBanner'),
        History: () => import(/* webpackChunkName: "public/components/elements/accounts/History" */ '/public/components/elements/accounts/History'),
        BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base" */ '/public/components/common/uiKit/button/base'),
        ReturnButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/return" */ '/public/components/common/uiKit/button/return'),
        CommissionsList: () => import(/* webpackChunkName: "public/components/elements/accounts/Commissions/List" */ '/public/components/elements/accounts/Commissions/List'),

        CommonInfoModal: () => import(/* webpackChunkName: "public/components/modals/commonInfo" */ '/public/components/modals/commonInfo'),
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    props: {
        accountId: undefined,
    },
    data () {
        return {
            isDownCommissionOpen: 0,
            openInfoModal: () => {},
            showCommissionsPage: false,
            contactUsBannerShow: false,
            showZeroState: false
        };
    },
    async beforeMount() {
        await this.checkDownCommissionState();
    },
    created () {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_advanced_balance_history;

        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }

        this.contactUsBannerShow = import.meta.env.VITE_IS_SUPPORT_ACTIVE === '1'
    },
    methods: {
        ...mapActions('dashboard', [
            'fetchDashboard',
        ]),
        ...mapActions('app', [
            'openModal',
            'request',
        ]),
      goToTelegram () {
        window.open('https://t.me/onchainpay', '_blank')
      },
        openReplenishRequestModal() {
          const { openModal, firstAccount } = this;

          openModal({
            name: 'ChoiceStepModal',
            payload: {
              accountId: firstAccount.accountId,
              currencyBalanceId: firstAccount.currencyId,
            },
          })
        },
        async checkDownCommissionState () {
            const storageState = sessionStorage.getItem('downCommission');
            if (storageState === null) {
                const {data: {getUserContent: {code, result}}} =  await this.request({
                    type: 'query',
                    gql: getUserContent,
                });
                if (code === 0) {
                    const formattedBoolean = Number(result.showCommissionWindow);
                    this.isDownCommissionOpen = formattedBoolean;
                    sessionStorage.setItem('downCommission', formattedBoolean);
                    return;
                }
            }
            this.isDownCommissionOpen = Number(storageState);
        },
        closeDownCommission () {
            this.isDownCommissionOpen = false;
        }
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapState('transactions', [
            'isInfoOpened',
        ]),
        ...mapState('accounts', {
            loading: state => state.loading,
            accounts: state => state.accounts,
            accountData: state => state.accountData,
            tariffs: state => state.tariffs,
            getBalanceFee: state => state.getBalanceFee,
        }),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('app', ['theme']),
        firstAccount({ accounts }) {
          return accounts[0]
        },
        themeStatus () {
          if (!this.theme) {
            let theme = localStorage.getItem('data-theme-private');

            return theme === 'dark';
          }
          return this.theme
        },
        hasAccounts({ accounts }) {
          return accounts && accounts.length
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.advanced-balances.title'),
            subtitle: this.$t('zero-states.advanced-balances.subtitle'),
            notifications: [
              this.$t('zero-states.advanced-balances.notifications.first-line'),
              this.$t('zero-states.advanced-balances.notifications.second-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.advanced-balances.actions.replenishment.title'),
                info: this.$t('zero-states.advanced-balances.actions.replenishment.info'),
                btnName: this.$t('zero-states.advanced-balances.actions.replenishment.btn-name'),
                action: 'open-replenish-request-modal'
              },
              {
                title: this.$t('zero-states.advanced-balances.actions.api-integration.title'),
                info: this.$t('zero-states.advanced-balances.actions.api-integration.info'),
                btnName: this.$t('zero-states.advanced-balances.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/02advanced_account/post_advanced-balance-deposit-address'
              }
            ],
            type: 'default'
          }
        }
    }
};

</script>

<style lang="scss" scoped>
.accounts {
  min-width: 79vw;
  max-width: 79vw;

  //padding: 0 30px 30px 30px;
  margin-top: -80px;
  padding-left: 30px;

  @include below_1180() {
    min-width: 99vw;
    max-width: 99vw;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -10px;
  }

  font-family: var(--new-front-font-family);

  &__title {
    display: flex;
    align-items: center;

    margin-bottom: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-primary);
  }

  &__subtitle {
    margin-bottom: var(--margin-h1-bottom);
    font-style: normal;
    font-weight: 400;
    font-size: $h3;
    color: var(--new-front-secondary-font-color);
    max-width: 70%;
    @include below_1180() {
      max-width: 100%;
    }
  }

  &__header-and-body {
    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);

    .account-header {
      display: flex;
      align-items: center;
      justify-content: start;

      height: 295px;

      @include below_768() {
        height: 370px;
      }

      @include below_1180() {
        width: 100%;
      }

      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        height: 5px;
        background: var(--new-front-scrollbar-bg);
      }
      &::-webkit-scrollbar-thumb {
        background: #744DB2;
        border-radius: 3px;
      }

      .account-card {
        width: 100%;
        min-width: 470px;
      }

      .my-commissions-banner, .account-card {
        margin-bottom: 10px;
        height: calc(100% - 10px);
      }

      .my-commissions-banner {
        margin-left: 30px;
      }

      @include below_768() {
        .my-commissions-banner {
          display: none;
        }
      }

      @include below_phone() {
        .account-card {
          min-width: 100%;

          .spend-title {
            font-size: $h6;
          }
          .left-block {
            width: 50%;
            .input-wrapper {
              .selected {
                font-size: $h5;
              }
            }
          }
          .right-block {
            width: 50%;
            .id {
              font-size: $h6;
            }
          }
        }
      }
    }
    .account-history {
      margin-top: 30px;
    }

    .commissions {
      display: flex;

      @include below_768() {
        flex-direction: column;
      }

      &__list-wrapper {
        width: 100%;
        height: 100%;
        max-height: 77vh;

        margin-right: 30px;

        overflow: auto;

        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          height: 5px;
          background: var(--new-front-scrollbar-bg);
        }
        &::-webkit-scrollbar-thumb {
          background: #744DB2;
          border-radius: 3px;
        }

        @include below_768() {
          order: 2;
        }
      }
      &__contact-us {
        position: relative;

        max-width: 400px;
        min-width: 400px;

        height: 380px;

        @include below_768() {
          width: 100%;
          max-width: unset;
          min-width: unset;
          height: 280px;
          margin-bottom: 10px;
          order: 1;
        }

        @include below_phone() {
          height: 380px;
        }

        border-radius: 30px;

        background-color: var(--new-front-bg-secondary);
        padding: 30px;

        font-family: var(--new-front-font-family);

        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .info-wrapper {
          .title {
            color: var(--new-front-text-primary-bg-secondary);
            font-size: $h1;
            line-height: 38px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
          .description {
            height: 90px;
            color: var(--new-front-text-secondary-bg-secondary);
            font-size: $h4;
            line-height: 20px;
            font-weight: 500;
          }
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: end;
        }

        .close-btn {
          position: absolute;
          top: 25px;
          right: 25px;
          cursor: pointer;
        }
      }

      @include below_phone() {
        &__contact-us {
          height: fit-content;

          .info-wrapper {
            .description {
              height: auto;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }
}


.accountData__pagination {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 950px) {
  .accounts {
    &__title, &__subtitle {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 20px;
      margin-bottom: 5px;
    }
    &__subtitle {
      font-size: $h6;
    }
    &__title {
      font-size: $h3;
    }
    &__subtitle {
      font-size: $h6;
    }
  }
}
</style>
