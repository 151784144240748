<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div v-else class="balances">
        <Container>
            <div class="balances__list">
                <div class="balances__list-header">
                    <p class="balances__list-header__item ">
                        {{ $t('information.currency') }}
                    </p>
                    <p class="balances__list-header__item ">
                        {{ $t('information.network') }}
                    </p>
                    <p class="balances__list-header__item ">
                        {{ $t('information.commission') }}
                    </p>
                    <p class="balances__list-header__item ">
                        {{ $t('information.type') }}
                    </p>
                    <p class="balances__list-header__item ">
                        {{ $t('information.commission-source') }}
                    </p>
                </div>

                <div class="balance  balances__balance"
                     v-for="(data, index) in paginatedSortedFees" :key="`${index}w`"
                     :class="{ 'balance balances__balance not-allow': !data.network.allowWithdrawal  }"
                >
                    <div class="balance__currency">
                        <CurrencyImg
                          class="balance__currency-img"
                          :currency="data.currency"
                        />
                        <span class="balance__amount">{{ data.alias }}<span class="secondary-text">({{ data.currency }}) </span></span>
                    </div>
                    <p class="balance__amount ">
                        <NetworkIcon class="balance__currency-img" :network="data.network.name"/>
                        {{ data.network.name | getNetworkFullNameByNetworkName}}<span class="secondary-text">{{ data.network.name | getNetworkShortNameByNetworkName }} </span>
                    </p>
                    <p class="balance__amount">
                        {{ data.network.networkFee }} {{ data.currency }} (${{ data.network.networkFeeUSD }})
                    </p>
                    <p class="balance__amount ">
                        {{ data.network.contract ? $t('information.token') : $t('information.native') }}
                    </p>
                    <p class="balance__amount ">
                        {{ data.network.contract ? $t('information.advance-balance') : $t('information.withdrawal-amount') }}
                    </p>
                </div>
                <div :class="['balance-mobile', openedItem.id === data.id && openedItem.network === data.network.id ? 'balance-mobile--active' : '', !data.network.allowWithdrawal ? 'not-allow' : '' ]" v-for="(data ,index) in paginatedSortedFees" :key="`${index}m`" @click="openItem({network: data.network.id, id: data.id })">
                   <div class="balance-mobile__header">
                      <div class="balance__currency">
                          <CurrencyImg
                            class="balance__currency-img"
                            :currency="data.currency"
                          />
                          <span class="balance__amount">{{ data.alias }}<span class="secondary-text">({{ data.currency }})</span></span>
                      </div>
                      <svg :class="['chevron', openedItem.id === data.id && openedItem.network === data.network.id ? 'chevron--active' : '' ]" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.712375 1.635C0.569375 1.462 0.484375 1.241 0.484375 1C0.484375 0.448 0.932375 0 1.48438 0H7.48438C8.03638 0 8.48438 0.448 8.48438 1C8.48438 1.241 8.39937 1.462 8.25637 1.635L5.29238 5.589C5.11037 5.838 4.81637 6 4.48438 6C4.15237 6 3.85837 5.838 3.67637 5.589L0.712375 1.635Z" fill="var(--new-front-primary-font-color)"/>
                      </svg>
                  </div>
                    <div class="balance-mobile__content">
                        <div class="balance-mobile__item">
                            <div class="balance-mobile__item-title secondary-text">{{ $t('information.network') }}:</div>
                            <p class="balance__amount ">
                                {{ data.network.name | getNetworkFullNameByNetworkName}}<span class="secondary-text">{{ data.network.name | getNetworkShortNameByNetworkName }} </span>
                            </p>
                        </div>
                        <div class="balance-mobile__item">
                            <div class="balance-mobile__item-title secondary-text">{{ $t('information.commission') }}:</div>
                            <p class="balance__amount ">
                                {{ data.network.networkFee }} {{ data.currency }} (${{ data.network.networkFeeUSD }})
                            </p>
                        </div>
                        <div class="balance-mobile__item">
                            <div class="balance-mobile__item-title secondary-text">{{ $t('information.type') }}:</div>
                            <p class="balance__amount ">
                                {{ data.network.contract ? $t('information.token') : $t('information.native') }}
                            </p>
                        </div>
                        <div class="balance-mobile__item">
                            <div class="balance-mobile__item-title secondary-text">{{ $t('information.commission-source') }}:</div>
                            <p class="balance__amount ">
                                {{ data.network.contract ? $t('information.advance-balance') : $t('information.withdrawal-amount') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-if="sortedFees.length > 10" :page-size="pageSize" :current="page" @page-change="paginate"
                        :total="sortedFees.length"/>
        </Container>
    </div>

  </div>
</template>

<script>

import {mapState} from 'vuex';
import AtPagination from "at-ui/src/components/pagination";
import BalancesTopBar from "/public/components/elements/balances/BalancesTopBar";
import BalancesListHeader from "/public/components/elements/balances/BalancesListHeader";
import Balance from "/public/components/elements/balances/Balance";
import EmptyTable from "/public/components/elements/EmptyTable";
import CreateWithdrawalRequestModal from "/public/components/modals/requests/CreateWithdrawalRequestModal";
import CurrencyImg from "../components/elements/CurrencyImg";
import _ from "lodash";
import {getNetworkName, getCurrencyTicker, getNetworkFullNameByNetworkName} from "/public/filters";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";
import Container from "@/public/components/common/uiKit/Container.vue";
import NetworkImg from "@/public/components/common/uiKit/networkImg/index.vue";
import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";

export default {
    name: 'WithdrawalFees',
    title: 'information.title',
    components: {
        NetworkIcon,
        NetworkImg,
        Container,
        Preloader,
        CurrencyImg,
        BalancesTopBar,
        BalancesListHeader,
        Balance,
        EmptyTable,
        AtPagination,
        CreateWithdrawalRequestModal,
        Pagination,
    },
    data() {
        return {
            searchText: '',
            page: 1,
            pageSize: 20,
            hideEmptyBalances: false,
            openedItem: ''
        };
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
            networks: state => state.networks,
            isLoading: state => state.isLoading,
            isInit: state => state.isInit,
        }),
        ...mapState('withdrawalRequests', {
            fees: state => state.fees,
        }),

        loading() {
            return !this.fees.length;
        },
        sortedFees() {
            return _.sortBy(this.allCurrenciesNetwork, ["alias", "networkName"]);
        },
        paginatedSortedFees() {
            return this.sortedFees.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
        },
        allCurrenciesNetwork() {
            const allOptions = [];
            for (const currency of this.currencies) {
                for (const network of currency.networks) {
                    allOptions.push({
                        ...currency,
                        network: network,
                        networkName: getNetworkName(network.id),
                    });
                }
            }
            return allOptions;
        },
    },
    methods: {
        getNetworkFullNameByNetworkName,
        paginate(page) {
            this.page = page;
        },
        openItem ({ network, id }) {
            this.openedItem = this.openedItem.id === id && this.openedItem.network === network  ? { network: '', id: '' } : { network, id }
        }
    }
}

</script>

<style lang="scss" scoped>

.balances {
    padding: 0 30px 30px 30px;

  &__list-container {
    box-shadow: var(--shadow-container);
    border-radius: 20px;
    overflow: hidden;
    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);
  }

  &__list-header {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-gap: 5px;
    align-items: center;
    padding: 0 $p30;
    min-height: 56px;
    background: var(--new-front-select-item-bg-active);
    border-radius: 10px;
    @media screen and (max-width: 950px) {
      display: none;
    }
    &__item {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: $Gilroy;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);;
      font-weight: 500;
    }
  }

  &__balance {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    padding: 0 $p30;
    align-items: center;
    background: transparent;
    min-height: 88px;
    border-bottom: 1px solid var(--new-front-divider-color);
    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  &__title {
    font-family: $Gilroy;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    font-weight: 400;
    margin-bottom: 5px;
    width: 100%;
    @media screen and (max-width: 950px) {
      font-size: 20px;
    }
  }

  &__paragraph {

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: $h3;
    color: var(--new-front-secondary-font-color);
    margin-bottom: 30px;
    @media screen and (max-width: 950px) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }

  &__top-bar {
    margin-bottom: 16px;
  }
}

.not-allow {
  opacity: .3;
}

.balance {

  &__currency {
    display: flex;
    font-family: $Gilroy;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__amount {
    display: flex;
    align-items: center;
    font-family: $Gilroy;
    font-size: $h4;
    color: var(--new-front-primary-font-color);;
    font-weight: 500;
  }

  &__currency-name {
    margin-left: 6px;
    color: var(--primary-font-color);
  }
}
.balance-mobile {
  display: none;
}
@media screen and (max-width: 1380px) {

  .balances {

  }
}

@media screen and (max-width: 950px) {

  .balances {
      padding: 0 10px 10px 10px;
    &__title {
      padding-left: 10px;
    }

    &__paragraph {
      padding-left: 10px;
    }

    &__list-header {
      padding-left: 10px;
    }

    &__balance {
      padding-left: 10px;
    }

  }
  .balance-mobile {
    display: block;
    max-height: 62px;
    border-bottom: 1px solid var(--new-front-divider-color);
    transition: max-height .3s ease;
      overflow: hidden;
    &--active {
        max-height: 280px;
    }
  }
    .balance-mobile__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        border-bottom: 1px solid var(--new-front-divider-color);
        &:last-of-type {
            border-bottom: none;
        }
        &-title {
            font-size: $h6;
            font-family: $Gilroy;
        }
    }
  .balance-mobile__header {
      display: grid;
      grid-template-columns: 1fr 10px;
      grid-gap: 10px;
    padding: 20px 15px;
      align-items: center;
  }
  .balance-mobile__content {
        padding: 0 20px;
  }
    .balance__amount {
        font-size: 13px;
    }
}

.secondary-text {
  color: var(--new-front-secondary-font-color);
  margin-left: 5px;
}
.chevron {
    transition: transform .3s ease;
    &--active {
        transform: rotate(180deg);
    }
}
</style>
