import getAccountsQuery from "/public/graphQl/queries/getAccounts";

export const fetchAccounts = async ({ state, commit, rootGetters, dispatch }) => {
    if (state.loading) {
        return;
    }

    commit('setLoading', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getAccountsQuery,
            variables: { organizationId },
        }, {root: true});
    } catch (error) {
        console.error('Fail fetch accounts', error);
    } finally {
        commit('setLoading', false);
    }

    const {
        data: {
            getAccounts: accounts,
        },
    } = data;

    for (const account of accounts) {
        const result = await dispatch('balances/getAccountBalancesGrouped',
            {organizationId, accountId: account.accountId}, {root: true}).catch(e => e);

        if (result instanceof Error) {
            console.error(result);
        }
    }

    commit('setAccounts', accounts);

    return accounts;
};
