import gql from 'graphql-tag';

export const HistoryItemFragment = gql`
  fragment CollectDashboardHistoryItem on CollectDashboardHistoryItem {
    len
    payin {
        amountUSD
        amountEUR
        amountRUB
        amountINR
        fee {
            amountUSD
            amountEUR
            amountRUB
            amountINR
        }
    }
    personal {
        amountUSD
        amountEUR
        amountRUB
        amountINR
        fee {
            amountUSD
            amountEUR
            amountRUB
            amountINR
        }
    }
  }
`;

export default gql`
    query ($organizationId: String!, $intervalInDays: Int!) {
      collectDashboard(organizationId: $organizationId, intervalInDays: $intervalInDays) {
        stats {
          orders {
            countPaid
            countUnPaid
            countPaidLastInterval
            countUnPaidLastInterval
            
            amountUSD
            amountUSDLastInterval
            
            amountEUR
            amountEURLastInterval
                      amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
            
            currencies {
              id
              amount
              amountLastPeriod
              countPaid
              countUnPaid
              countPaidLastInterval
              countUnPaidLastInterval
              amountUSD
              amountUSDLastInterval
              amountEUR
              amountEURLastInterval
              amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
            }
            networks {
              id
              amount
              amountLastPeriod
              countPaid
              countUnPaid
              countPaidLastInterval
              countUnPaidLastInterval
              amountUSD
              amountUSDLastInterval
              amountEUR
              amountEURLastInterval
              amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
            }
          }
          
          personal {
            count
            countLastInterval
            
            amountUSD
            amountUSDLastInterval
            
            amountEUR
            amountEURLastInterval
            
            amountRUB
            amountRUBLastInterval
            amountINR
            amountINRLastInterval
            
            currencies {
              id
              amount
              amountLastPeriod
              count
              countLastInterval
              amountUSD
              amountUSDLastInterval
              amountEUR
              amountEURLastInterval
              amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
            }
            networks {
              id
              amount
              amountLastPeriod
              count
              countLastInterval
              amountUSD
              amountUSDLastInterval
              amountEUR
              amountEURLastInterval
              amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
            }
          }
        }
        
        availableBalances {
          amountUSD
          amountUSDLastInterval
          amountEUR
          amountEURLastInterval
                    amountRUB
              amountRUBLastInterval
              amountINR
              amountINRLastInterval
          currencies {
            id
            amount
            amountUSD
            amountEUR
            amountRUB
            amountINR
          }
          networks {
            id
            amount
            amountUSD
            amountEUR
            amountRUB
            amountINR
          }
          addresses {
            id
            address
            tag
            currencyId
            networkId
            amount
            amountLastInterval
            amountUSD
            amountUSDLastInterval
            amountEUR
            amountEURLastInterval
            amountRUB
            amountRUBLastInterval
            amountINR
            amountINRLastInterval
            riskLevel
            alias
            comment
          }
        }
        amountToCollect {
          availableToCollect {
            amountUSD
            amountEUR
            amountRUB
            amountINR
            currencies {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
            networks {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
          }
          dustActives {
            amountUSD
            amountEUR
            amountRUB
            amountINR
            currencies {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
            networks {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
          }
          highRisk {
            amountUSD
            amountEUR
            amountRUB
            amountINR
            currencies {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
            networks {
              id
              amount
              amountUSD
              amountEUR
              amountRUB
              amountINR
            }
          }
        }
        history {
            total {
                ...CollectDashboardHistoryItem
            }
            small {
                ...CollectDashboardHistoryItem
            }
            dates {
                date
                total {
                  ...CollectDashboardHistoryItem  
                }
                small {
                  ...CollectDashboardHistoryItem  
                }
            }   
        }
      }
    }
    ${HistoryItemFragment}
`
