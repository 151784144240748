import Vue from 'vue';
import Store from './store';
import moment from "moment";
import {TRANSACTION_HISTORY_TYPE_ENUM} from "../common/constants/transaction-history-type-enum";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../common/constants/transaction-history-status-enum";
import {PROCESSING_FACADE_ERROR_CODES} from "../common/constants/api-error-codes";
import TRANSACTION_HISTORY_BASIS_ENUM from "../common/constants/transaction-history-basis-enum";
import TRANSACTION_HISTORY_SOURCE_ENUM from "../common/constants/transaction-history-source-enum";
import {BLOCKCHAIN_TRANSACTIONS_TYPE_ENUM} from "../common/constants/blockchain-transactions-type";
import {REGISTRY_STATUS_ENUM} from "../common/constants/registry-status-enum";
import TRANSACTION_HISTORY_METHOD_ENUM from "../common/constants/transaction-history-method-enum";
import {PRE_CHECKOUT_ORDER_STATUS_ENUM} from "../common/constants/pre-checkout-order-status-enum";

export const getCurrencyInfoById = (id) => {
    return Store.state.app.currencies?.find(c => c.id === id);
};
export const getNetworkInfoById = (id) => {
    return Store.state.app.networks?.find(c => c.id === id);
};
export const getCurrencySymbol = (id) => {
    const currency = Store.state.app.currencies?.find(c => c.id === id);
    return currency.isFiat ? '$' : currency.currency;
};
export const getCurrencyTicker = (id) => {
    return Store.state.app.currencies?.find(c => c.id === id)?.currency;
};
export const getCurrencyAliasById = (id) => {
    return Store.state.app.currencies?.find(c => c.id === id)?.alias;
};
export const getFormatDate = (date, format = 'DD.MM.YYYY HH:mm') => {
    return moment(date).format(format);
};

export const shortText = (text, startLen = 5, endLen = 5) => {
    // TODO этот фильт по всему проекту заменить на getTruncatedString
    return `${text}`
        .replace(new RegExp(`^([a-zA-Z0-9:-]{${startLen}})(.*)([a-zA-Z0-9:-]{${endLen}})\$`, 'g'), '$1...$3');
};
export const getTruncatedString = (txt, startLen = 4, endLen = 4) => {
    return (txt && txt.length > ( startLen + endLen )) ? `${txt.substr(0, startLen)}****${txt.substr(-endLen)}` : txt
};

export const truncateAmount = (amount, precision = 8) => {
    const truncated = `${amount}`.matchAll(new RegExp(`^([0-9]+)(?:([.,]{1})([0-9]{1,${precision}}))?`, 'g')).next().value;
    return truncated ? truncated[0] : `${amount}`;
};
export const prettifyAmount = (amount, decimalCount = 8, decimal = ".", thousands = " ") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e);
    }
};
export const prettifyAmountWithAbbreviations = amount => {
    // 999.76 -> 999.76
    // 1000 -> 1K
    // 1001 -> ~1K
    // 12000000 - > 12M
    // 12000001 - > ~12M

    amount = amount.toString().replace(/,/g, '.').replace(/\s/g, '')

    const getAbbr =(amount, delimiter, char)=> {
        const remainder = Number(amount)%delimiter
        const integer = (Number(amount)/delimiter).toFixed(0)

        if (remainder) {
            return `~${integer}${char}`
        } else {
            return `${integer}${char}`
        }
    }

    if ( Number(amount) < 999.99999999 ) {
        return amount
    } else if ( Number(amount) >= 1000000 ) {
        return getAbbr(amount, 1000000, 'M')
    } else {
        return getAbbr(amount, 1000, 'K')
    }
};
export const hideZeros = (amount, delimiter = '.') => {
    const stringAmount = `${amount}`;

    const [withoutDecimals, decimals] = stringAmount.split(delimiter);
    if (Number(decimals))
        return amount;
    else
        return withoutDecimals;
};
export const prettyAmountWithHideZeros = ( amount, signsAfterDot ) => {
    if (!amount) return amount

    if (signsAfterDot) {
        return parseFloat( Number(amount).toFixed(signsAfterDot) )
    } else {
        return parseFloat( Number(amount) )
    }
};
export const separatePeriods = (v) => {
    const preparedValue = v.toString().replace(/,./g, '.').replace(/[^\d.]/g, "", '')
    // Сначала заменяются все запятые на точки, потом заменяются все пробелы и буквы на пустоту
    // Разделение по разрядам с учетом дробных частей
    const parts = preparedValue.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join('.')
}

export const pluralize = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};
export const uploadRegistryErrors = (code) => {
    switch (code) {
    case(1005):
        return 'upload_registry.errors.amount_lte_zero';
    case(1013):
        return 'upload_registry.errors.incorrect_address';
    case(1014):
        return 'upload_registry.errors.incorrect_tag';
    case(1019):
        return 'upload_registry.errors.currency_not_found';
    case(1061):
        return 'upload_registry.errors.fail_to_process_bch';
    case(1064):
        return 'upload_registry.errors.payout_address_not_found';
    case(1077):
        return 'upload_registry.errors.invalid_delimiter';
    case(1078):
        return 'upload_registry.errors.invalid_amount_of_columns';
    case(1079):
        return 'upload_registry.errors.required_fields_are_empty';
    case(1069):
        return 'upload_registry.errors.network_not_found';
    case(1080):
        return 'upload_registry.errors.too_many_lines';
    default:
        return 'upload_registry.errors.unknown';
    }

};
export const transferAddressType = (type) => {
    const ADDRESS_TYPE = {
        PAY_IN: 'transactions.transfer_type.pay_in',
        PAY_OUT: 'transactions.transfer_type.pay_out',
        ADVANCE: 'transactions.transfer_type.advance',
    };
    return ADDRESS_TYPE[type] || 'transactions.transfer_type.unknown';
};
export const registryStatusType = (status) => {
    const statusType = {
        [REGISTRY_STATUS_ENUM.INIT]: 'upload_registry.status.init',
        [REGISTRY_STATUS_ENUM.PENDING]: 'upload_registry.status.pending',
        [REGISTRY_STATUS_ENUM.PROCESSED]: 'upload_registry.status.processed',
        [REGISTRY_STATUS_ENUM.ERROR]: 'upload_registry.status.error',
        [REGISTRY_STATUS_ENUM.PARTIAL]: 'upload_registry.status.partial',
    };
    return statusType[status] || 'upload_registry.status.unknown';
};

export const registryStatusTypeColor = (status) => {
    const statusType = {
        [REGISTRY_STATUS_ENUM.INIT]: '--blue-300',
        [REGISTRY_STATUS_ENUM.PENDING]: '--blue-300',
        [REGISTRY_STATUS_ENUM.PROCESSED]: '--green-200',
        [REGISTRY_STATUS_ENUM.ERROR]: '--red-200',
        [REGISTRY_STATUS_ENUM.PARTIAL]: '--yellow-200',
    };
    return statusType[status] || '--black-200';
};


export const transactionHistoryType = (type) => {
    const types = {
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION]: 'transactions.commission',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_CREATE_ORDER]: 'transactions.commission_create_order',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_EXECUTE_ORDER]: 'transactions.commission_execute_order',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_DEPOSIT]: 'transactions.commission_wallet_deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_DEPOSIT]: 'transactions.commission_recurrent_deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_DEPOSIT]: 'transactions.commission_personal_deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_DEPOSIT]: 'transactions.commission_payout_deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_WITHDRAWAL]: 'transactions.commission_wallet_withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_WITHDRAWAL]: 'transactions.commission_recurrent_withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_WITHDRAWAL]: 'transactions.commission_personal_withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_WITHDRAWAL]: 'transactions.commission_payout_withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_COLLECT]: 'transactions.deposit_collect',
        [TRANSACTION_HISTORY_TYPE_ENUM.NETWORK_FEE]: 'transactions.network_fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT]: 'transactions.deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.ADVANCED_BALANCE_REFUND]: 'transactions.advanced_balance_refund',
        [TRANSACTION_HISTORY_TYPE_ENUM.WITHDRAWAL]: 'transactions.withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE]: 'transactions.exchange',
        [TRANSACTION_HISTORY_TYPE_ENUM.PARTNERS]: 'transactions.partners',
        [TRANSACTION_HISTORY_TYPE_ENUM.REFERRALS]: 'transactions.referrals',
        [TRANSACTION_HISTORY_TYPE_ENUM.ORFAN_DEPOSIT]: 'transactions.orfan_deposit',
        [TRANSACTION_HISTORY_TYPE_ENUM.ORFAN_WITHDRAWAL]: 'transactions.orfan_withdrawal',
        [TRANSACTION_HISTORY_TYPE_ENUM.ROLLING_RESERVE]: 'transactions.rolling_reserve',
        [TRANSACTION_HISTORY_TYPE_ENUM.ORDER_TRANSACTION]: 'transactions.order_transaction',
        [TRANSACTION_HISTORY_TYPE_ENUM.TRANSFER]: 'transactions.transfer',
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_TRANSFER]: 'transactions.commission-transfer',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PAYOUT_BALANCE]: 'transactions.deposit_payout_balance',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_WALLET]: 'transactions.deposit_wallet',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_RECURRENT]: 'transactions.deposit_recurrent',
        [TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PERSONAL]: 'transactions.deposit_personal',
        [TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL]: 'transactions.bridge_internal',
        [TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL]: 'transactions.bridge_external',
        [TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL_FEE]: 'transactions.bridge_internal_fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL_FEE]: 'transactions.bridge_external_fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.KYT_TRANSACTION_FEE]: 'advance_balances.kyt-transaction-fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.KYT_WITHDRAWAL_ADDRESS_FEE]: 'advance_balances.kyt-withdrawal-address-fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.KYT_ADDRESS_FEE]: 'advance_balances.kyt_address_fee',
        [TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO]: "advance_balances.exchange-auto",
        [TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO_FEE]: "advance_balances.exchange-auto-fee",
        [TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_INTERNAL]: "transactions.exchange_internal",
        [TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_INTERNAL_FEE]: "transactions.exchange_internal_fee",
        [TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL_FEE]: "advance_balances.orphan-deposit-withdrawal-fee",
        [TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL]: "advance_balances.orphan-deposit-withdrawal",
        [TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT]: "advance_balances.orphan-deposit",
        [TRANSACTION_HISTORY_TYPE_ENUM.GAS_DEPOSIT]: "advance_balances.gas-deposit",
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WITHDRAWAL]: "transactions.basis_enum.commission_withdrawal",
        [TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP]: "transactions.basis_enum.payout_auto_swap",
        [TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP_FEE]: "transactions.basis_enum.payout_auto_swap_fee",
        [TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_COLLECT_WITHDRAWAL]: "transactions.commission_collect_withdrawal"
    };
    return types[type] || 'transactions.empty';
};

export const transactionHistoryStatus = (status) => {
    const statuses = {
        [TRANSACTION_HISTORY_STATUS_ENUM.INIT]: 'transactions.init',
        [TRANSACTION_HISTORY_STATUS_ENUM.ERROR]: 'transactions.error',
        [TRANSACTION_HISTORY_STATUS_ENUM.PENDING]: 'transactions.pending',
        [TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED]: 'transactions.executed',
        [TRANSACTION_HISTORY_STATUS_ENUM.REJECTED]: 'transactions.rejected',
    };

    return statuses[status] || 'transactions.empty';
};

export const preCheckoutPageOrderStatus = (status) => {
    const statuses = {
        [PRE_CHECKOUT_ORDER_STATUS_ENUM.CREATED]: 'donation-order.status_enum.init',
        [PRE_CHECKOUT_ORDER_STATUS_ENUM.ERROR]: 'donation-order.status_enum.error',
        [PRE_CHECKOUT_ORDER_STATUS_ENUM.PENDING]: 'donation-order.status_enum.pending',
        [PRE_CHECKOUT_ORDER_STATUS_ENUM.PROCESSED]: 'donation-order.status_enum.executed',
        [PRE_CHECKOUT_ORDER_STATUS_ENUM.REJECTED]: 'donation-order.status_enum.rejected',
    };

    return statuses[status] || 'transactions.empty';
};

export const transactionHistoryMethod = (status) => {
    const statuses = {
        [TRANSACTION_HISTORY_METHOD_ENUM.WEB]: 'transactions.method_enum.web',
        [TRANSACTION_HISTORY_METHOD_ENUM.API]: 'transactions.method_enum.api',
        [TRANSACTION_HISTORY_METHOD_ENUM.PRE_CHECKOUT]: 'transactions.method_enum.pre_checkout',
    };

    return statuses[status] || 'transactions.empty';
};

export const orderTransactionType = (type) => {
    const types = {
        [BLOCKCHAIN_TRANSACTIONS_TYPE_ENUM.IN]: 'order.transaction.type_enum.in',
        [BLOCKCHAIN_TRANSACTIONS_TYPE_ENUM.OUT]: 'order.transaction.type_enum.out',
    };
    return types[type] || 'order.empty';
};

export const orderTransactionTypeCurrency = (type) => {
    const types = {
        [BLOCKCHAIN_TRANSACTIONS_TYPE_ENUM.IN]: 'order.transaction.currency_type_enum.in',
        [BLOCKCHAIN_TRANSACTIONS_TYPE_ENUM.OUT]: 'order.transaction.currency_type_enum.out',
    };
    return types[type] || 'order.empty';
};

export const transactionHistoryBasis = (basis) => {
    const bases = {
        [TRANSACTION_HISTORY_BASIS_ENUM.ORDER]: 'transactions.basis_enum.order',
        [TRANSACTION_HISTORY_BASIS_ENUM.WITHDRAWAL]: 'transactions.basis_enum.withdrawal',
        [TRANSACTION_HISTORY_BASIS_ENUM.PAYMENT]: 'transactions.basis_enum.payment',
        [TRANSACTION_HISTORY_BASIS_ENUM.REFUND]: 'transactions.basis_enum.executed',
        [TRANSACTION_HISTORY_BASIS_ENUM.DEPOSIT]: 'transactions.basis_enum.deposit',
        [TRANSACTION_HISTORY_BASIS_ENUM.TRANSFER]: 'transactions.basis_enum.transfer',
        [TRANSACTION_HISTORY_BASIS_ENUM.KYT]: 'transactions.basis_enum.kyt',
        [TRANSACTION_HISTORY_BASIS_ENUM.EXCHANGE_AUTO]: 'transactions.basis_enum.exchange-auto',
        [TRANSACTION_HISTORY_BASIS_ENUM.EXCHANGE_INTERNAL]: 'transactions.basis_enum.exchange-internal',
        [TRANSACTION_HISTORY_BASIS_ENUM.EXCHANGE_INTERNAL_FEE]: 'transactions.basis_enum.exchange-internal-fee',
        [TRANSACTION_HISTORY_BASIS_ENUM.COLLECTING]: 'transactions.basis_enum.collecting',
        [TRANSACTION_HISTORY_BASIS_ENUM.COMMISSION_WITHDRAWAL]: 'transactions.basis_enum.commission_withdrawal',
        [TRANSACTION_HISTORY_BASIS_ENUM.PAYOUT_AUTO_SWAP]: 'transactions.basis_enum.payout_auto_swap'
    };

    return bases[basis] || 'transactions.empty';
};
export const transactionHistorySource = (source) => {
    const sources = {
        [TRANSACTION_HISTORY_SOURCE_ENUM.INTERNAL_TRANSACTION]: 'transactions.source_enum.internal',
        [TRANSACTION_HISTORY_SOURCE_ENUM.EXTERNAL]: 'transactions.source_enum.external',
    };

    return sources[source] || 'transactions.empty';
};
export const zeroNumberMask = (amount, mask = '') => {
    const n = Number(`${amount}`.replace(/[ ]/g, ''));

    return n ? amount : mask;
};
export const getNetworkSystemName = (networkId) => {
    if (!networkId) {
        return '-'
    }
    const network = Store.state.app.networks.find(network => network.id === networkId);

    if (!network) {
        return '';
    }

    return network.name;
};

export const getNetworkById = (networkId) => {
    const network = Store.state.app.networks.find(network => network.id === networkId || network.name === networkId);

    if (!network) {
        return {};
    }

    return network;
};

export const getNetworkName = (networkId) => {
    const network = Store.state.app.networks.find(network => network.id === networkId || network.name === networkId);

    if (!network) {
        return '-';
    }

    return network.alias;
};
export const getNetworkShortNameByNetworkName = (networkName, brackets = true ) => {
    switch (networkName) {
        case 'tron':
            return brackets ? '(TRC20)' : 'TRC20'
        case "ethereum":
            return brackets ? '(ERC20)' : 'ERC20'
        case "sepolia":
            return brackets ? '(ERC20)' : 'ERC20'
        case "bsc":
            return brackets ? '(BEP20)' : 'BEP20'
        case "bsc-testnet":
            return brackets ? '(BEP20)' : 'BEP20'
        case 'solana':
            return brackets ? '(Solana)' : 'Solana'
        case 'bitcoin':
            return brackets ? '(BTC)' : 'BTC'
        case 'fantom':
            return brackets ? '(Fantom)' : 'Fantom'
        case 'bitcoincash':
            return brackets ? '(BCH)' : 'BCH'
        case 'litecoin':
            return brackets ? '(LTC)' : 'LTC'
        case 'polygon':
            return brackets ? '(Polygon)' : 'Polygon'
        case 'ton':
            return brackets ? '(TON)' : 'TON'
        default:
            return '-'
    }
};
export const getNetworkShortNameByNetworksAlias = (networkAlias) => {
    let shortName = networkAlias.match(/\((.+?)\)/)

    if (!shortName) {
        if (networkAlias === 'Fantom') {
            shortName = "FTM"
        } else if (networkAlias === 'Solana') {
            shortName = "SOL"
        } else {
            shortName = networkAlias
        }
    } else {
        shortName = shortName[1]
    }

    return shortName
};
export const getNetworkFullNameByNetworkName = (networkName) => {
    switch (networkName) {
        case 'tron':
            return 'Tron'
        case "ethereum":
            return 'Ethereum'
        case "sepolia":
            return 'Ethereum'
        case "bsc":
            return 'Binance Smart Chain'
        case "bsc-testnet":
            return 'Binance Smart Chain'
        case 'solana':
            return 'Solana'
        case 'bitcoin':
            return 'Bitcoin'
        case 'fantom':
            return 'Fantom'
        case 'bitcoincash':
            return 'BitcoinCash'
        case 'litecoin':
            return 'Litecoin'
        case 'polygon':
            return 'Polygon'
        case 'ton':
            return 'The Open Network'
        default:
            return '-'
    }

};
export const preparedString = (string, start = 4, end = -4) => {
    const stringLength = string ? string.length : 0;


    if (string) {
        return stringLength >= 16 ? `${string.substr(0, start)}***${string.substr(end)}` : string;
    } else {
        return string;
    }

};
export const getErrorMessage = (code) => {
    if (code === 0) {
        return '';
    }

    if (code < 1000) {
        return 'error.server_error_contact_support';
    }

    switch (code) {
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_IS_REQUIRED:
        return 'error.it_is_necessary_to_choose_coin';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_IS_NOT_VALID:
        return 'error.no_coin_specified';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_IS_REQUIRED:
        return 'error.you_must_specify_the_amount';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_IS_NOT_VALID:
        return 'error.the_amount_is_specified_incorrectly';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_LTE_ZERO:
        return 'error.the_amount_cannot_be_less_than';
    case PROCESSING_FACADE_ERROR_CODES.ERROR_WEBHOOK_IS_REQUIRED:
        return 'error.error_webhook_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.ERROR_WEBHOOK_IS_NOT_VALID:
        return 'error.invalid_URL_passed_as_Error_Webhook';
    case PROCESSING_FACADE_ERROR_CODES.SUCCESS_WEBHOOK_IS_REQUIRED:
        return 'error.success_Webhook_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.SUCCESS_WEBHOOK_IS_NOT_VALID:
        return 'error.invalid_URL_passed_as_Success_Webhook';
    case PROCESSING_FACADE_ERROR_CODES.AVAILABLE_TILL_IS_NOT_VALID:
        return 'error.incorrect_expiration_date_of_the_order';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ITEMS_IS_NOT_ARRAY:
        return 'error.empty_output_list';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ADDRESS_IS_REQUIRED:
        return 'error.the_output_address_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ADDRESS_NOT_VALID:
        return 'error.invalid_output_address';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_TAG_NOT_VALID:
        return 'error.invalid_tag_specified';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ITEM_AMOUNT_IS_REQUIRED:
        return 'error.the_withdrawal_amount_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ITEM_AMOUNT_IS_NOT_VALID:
        return 'error.incorrect_withdrawal_amount';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ITEM_AMOUNT_LTE_ZERO:
        return 'error.the_withdrawal_amount_cannot_be_less_than';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_ITEM_ADDRESS_NOT_VALIDATED:
        return 'error.invalid_output_address';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_NOT_FOUND:
        return 'error.an_unknown_coin_is_indicated';
    case PROCESSING_FACADE_ERROR_CODES.NO_HAS_BALANCE:
        return 'error.insufficient_funds_for_the_operation';
    case PROCESSING_FACADE_ERROR_CODES.BALANCE_LESS_THEN_REQUESTED_AMOUNT:
        return 'error.the_balance_is_less_than_the_requested_amount';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_OR_EXCHANGE_FOR_CURRENCY_ALREADY_STARTED:
        return 'error.the_output_is_already_running_wait_for_it_to_finish_and_repeat';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_FROM_IS_REQUIRED:
        return 'error.the_debit_coin_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_FROM_IS_NOT_VALID:
        return 'error.incorrect_debit_coin';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_TO_IS_REQUIRED:
        return 'error.the_transfer_coin_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_TO_IS_NOT_VALID:
        return 'error.incorrect_crediting_coin';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_FROM_IS_REQUIRED:
        return 'error.the_amount_of_the_charge_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_FROM_IS_NOT_VALID:
        return 'error.incorrect_debit_amount';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_FROM_LTE_ZERO:
        return 'error.the_debit_amount_cannot_be_less_than';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_FROM_NOT_FOUND:
        return 'error.an_unknown_debit_coin_is_indicated';
    case PROCESSING_FACADE_ERROR_CODES.CURRENCY_TO_NOT_FOUND:
        return 'error.an_unknown_crediting_coin_is_specified';
    case PROCESSING_FACADE_ERROR_CODES.ORDER_ID_IS_REQUIRED:
        return 'error.order_ID_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.ORDER_ID_IS_NOT_VALID:
        return 'error.invalid_Order_ID';
    case PROCESSING_FACADE_ERROR_CODES.RETURN_URL_IS_NOT_VALID:
        return 'error.invalid_URL_passed_as_Return_URL';
    case PROCESSING_FACADE_ERROR_CODES.MERCHANT_ORDER_IS_TOO_LARGE:
        return 'error.the_custom_Order_ID_is_too_long';
    case PROCESSING_FACADE_ERROR_CODES.MERCHANT_DESCRIPTION_IS_TOO_LARGE:
        return 'error.payment_description_is_too_long';
    case PROCESSING_FACADE_ERROR_CODES.NETWORK_ID_IS_REQUIRED:
        return 'error.the_coin_network_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.NETWORK_ID_IS_NOT_VALID:
        return 'error.incorrect_Coin_network';
    case PROCESSING_FACADE_ERROR_CODES.ACCOUNT_ID_IS_REQUIRED:
        return 'error.the_advance_balance_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.ACCOUNT_ID_IS_NOT_VALID:
        return 'error.incorrect_advance_balance';
    case PROCESSING_FACADE_ERROR_CODES.ADDRESS_ID_IS_REQUIRED:
        return 'error.address_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.ADDRESS_ID_IS_NOT_VALID:
        return 'error.invalid_address';
    case PROCESSING_FACADE_ERROR_CODES.FEE_TOKEN_IS_REQUIRED:
        return 'error.no_token_specified_for_output';
    case PROCESSING_FACADE_ERROR_CODES.FEE_TOKEN_IS_INVALID:
        return 'error.invalid_token_passed_for_output';
    case PROCESSING_FACADE_ERROR_CODES.ACCOUNT_BALANCE_LESS_THAN_REQUESTED_FEE_AMOUNT:
        return 'error.insufficient_funds_on_the_advance_balance';
    case PROCESSING_FACADE_ERROR_CODES.AMOUNT_LESS_THAN_WITHDRAWAL_MIN_AMOUNT:
        return 'error.the_amount_is_less_than_the_minimum';
    case PROCESSING_FACADE_ERROR_CODES.NETWORK_IS_REQUIRED:
        return 'error.the_coin_network_is_not_specified';
    case PROCESSING_FACADE_ERROR_CODES.NETWORK_IS_NOT_VALID:
        return 'error.incorrect_coin_network';
    case PROCESSING_FACADE_ERROR_CODES.FEE_TOKEN_EXPIRED:
        return 'error.the_withdrawal_token_has_expired_repeat_the_request_again';
    case PROCESSING_FACADE_ERROR_CODES.FEE_TOKEN_GENERATED_FOR_OTHER_ACCOUNT_OR_ADDRESS:
        return 'error.withdrawal_token';
    case PROCESSING_FACADE_ERROR_CODES.EXCHANGE_NOT_ALLOWED:
        return 'error.the_exchange_is_prohibited';
    case PROCESSING_FACADE_ERROR_CODES.INACTIVE_CURRENCY:
        return 'error.the_coin_is_not_available_for_operations';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_NOT_ALLOWED_BY_CURRENCY_SETTINGS:
        return 'error.at_the_moment_the_withdrawal_is_suspended_in_this_coin';
    case PROCESSING_FACADE_ERROR_CODES.INACTIVE_NETWORK:
        return 'error.the_network_is_unavailable_for_operations';
    case PROCESSING_FACADE_ERROR_CODES.NETWORK_UNDER_MAINTENANCE:
        return 'error.the_network_is_undergoing_technical_work';
    case PROCESSING_FACADE_ERROR_CODES.WITHDRAWAL_NOT_ALLOWED_BY_NETWORK_SETTINGS:
        return 'error.at_the_moment_the_withdrawal_is_suspended_in_this_network';
    case PROCESSING_FACADE_ERROR_CODES.ACCOUNT_NOT_OWNED_BY_ORGANIZATION:
        return 'error.the_advance_balance_does_not_belong_to_the_current_organization';
    case PROCESSING_FACADE_ERROR_CODES.ADDRESS_NOT_OWNED_BY_ACCOUNT:
        return 'error.the_address_does_not_belong_to_the_current_user';
    case PROCESSING_FACADE_ERROR_CODES.SOURCE_BALANCE_LESS_THAN_REQUESTED_FEE_AMOUNT:
        return 'error.the_withdrawal_amount_exceeds_the_available_balance';
        case PROCESSING_FACADE_ERROR_CODES.INCORRECT_2FA:
            return 'error.error_two-fa';
    }

    return 'error.unknown_error_please_contact_support';
};

export const getPrettyAddress = (txt) => {
    if (!txt) {
        return ''
    }
    return `${txt.substr(0, 4)}***${txt.substr(-4)}`
}
export const getPrettyUrl = (txt) => {
    if (txt.length <= 15) {
        return txt
    }
    return `${txt.substr(0, 15)}...`
}

Vue.filter('getCurrencyTicker', getCurrencyTicker);
Vue.filter('getCurrencyAliasById', getCurrencyAliasById);
Vue.filter('getFormatDate', getFormatDate);
Vue.filter('shortText', shortText);
Vue.filter('truncateAmount', truncateAmount);
Vue.filter('prettifyAmount', prettifyAmount);
Vue.filter('hideZeros', hideZeros);
Vue.filter('prettyAmountWithHideZeros', prettyAmountWithHideZeros);
Vue.filter('pluralize', pluralize);
Vue.filter('preCheckoutPageOrderStatus', preCheckoutPageOrderStatus);
Vue.filter('registryStatusType', registryStatusType);
Vue.filter('registryStatusTypeColor', registryStatusTypeColor);
Vue.filter('uploadRegistryErrors', uploadRegistryErrors);
Vue.filter('orderTransactionTypeCurrency', orderTransactionTypeCurrency);
Vue.filter('orderTransactionType', orderTransactionType);
Vue.filter('transactionHistoryType', transactionHistoryType);
Vue.filter('transactionHistoryMethod', transactionHistoryMethod);
Vue.filter('transferAddressType', transferAddressType);
Vue.filter('transactionHistoryStatus', transactionHistoryStatus);
Vue.filter('transactionHistoryBasis', transactionHistoryBasis);
Vue.filter('transactionHistorySource', transactionHistorySource);
Vue.filter('zeroNumberMask', zeroNumberMask);
Vue.filter('getNetworkName', getNetworkName);
Vue.filter('getNetworkShortNameByNetworksAlias', getNetworkShortNameByNetworksAlias);
Vue.filter('getNetworkShortNameByNetworkName', getNetworkShortNameByNetworkName);
Vue.filter('getNetworkFullNameByNetworkName', getNetworkFullNameByNetworkName);
Vue.filter('getNetworkSystemName', getNetworkSystemName);
Vue.filter('preparedString', preparedString);
Vue.filter('getErrorMessage', getErrorMessage);
Vue.filter('getCurrencySymbol', getCurrencySymbol);
Vue.filter('getCurrencyInfoById', getCurrencyInfoById);
Vue.filter('getPrettyAddress', getPrettyAddress);
Vue.filter('getPrettyUrl', getPrettyUrl);
Vue.filter('prettifyAmountWithAbbreviations', prettifyAmountWithAbbreviations);
Vue.filter('separatePeriods', separatePeriods);
Vue.filter('getTruncatedString', getTruncatedString);
