import updateOrganizationSettingsMutation from "/public/graphQl/mutations/updateOrganizationSettings";

export const updateOrganizationSettings = async ({commit, dispatch, rootGetters, state}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let variables = {
        organizationId,
        field: payload.field,
        value: payload.value,
        method: payload.method,
        requestType: payload.requestType,
        isTemplate: payload.isTemplate,
        templateType: payload.templateType,
    };

    let response

    try {
        commit('app/toggleLoading', null, {root: true});
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: updateOrganizationSettingsMutation,
            variables,
        }, {root: true});
    } catch (error) {
        throw new Error(error.message);
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    return response?.data ? response.data.updateOrganizationSetting : {};
};
