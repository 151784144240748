import getAccountBalancesGrouped from "../../../../graphQl/queries/getAccountBalancesGrouped"; // клон getAccountBalancesGroupedPayin

export const getAccountBalancesGroupedQuery = async ({commit, dispatch, rootGetters}, payload) => {
    let balancesData;
    commit('app/toggleLoading', true, {root: true})

    const organizationId = rootGetters['app/getSelectedOrganization'].id
    const variables = {
        organizationId,
        ...payload,
    }

    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: getAccountBalancesGrouped,
            variables,
        }, {root: true})
    } catch (error) {
        return error
    } finally {
        commit('app/toggleLoading', null, {root: true})
    }

    commit('accountBalancesGrouped', balancesData.data?.getAccountBalancesGrouped)

    return balancesData
};
