import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getOrganization(organizationId: $organizationId) {
            id
            name
            participants
            ownerEmail
            kycApprove
            allowRecurrent
            allowBridge
            allowSwap
            logoUrl
            collectingMinimumAmount
            collectingInterval
            nextCollectingAt
            mainCurrencyId
            telegramUrl
            websiteUrl
            timezone
            isTest
            organizationUsers {
              id
              email
              name
              role {
                id
                name
                isOwner
              }
              lastLoginAt
            }
            organizationRoles {
              id
                name
                comment
            }
            collectingDust
        }
    }
`;
