export const state = {
    ordersCountOnPage: 10,
    orders: [],
    order: {},
    infoState: false,
    isLoading: false,
    totalOrdersCount: 0,
    filters: {
        status: [],
    },
    orderTransaction: null,
};
