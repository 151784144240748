<template>
    <div class="balances">
      <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :background-transparent="false"
      />
      <template v-else>
        <Container class="balances__top-container" :overflow="true">
          <BalancesTopBar
            class="balances__top-bar"
            :account-id="accountId"
            :currencies="getCurrencies"
            :networks="networks"
            @searchCurrency="setSearchText"
            @search="setSearchString"
            @selectCurrency="setCurrency"
            @selectNetwork="setNetwork"
            @selectRisk="selectRisk"
            @toggleEmptyBalances="toggleEmptyBalances"
          />

          <preloader v-if="isLoading || !isInit"/>
          <template v-if="!isLoading && hasAllBalances">
              <BalanceCard
                v-for="balance in paginateSortedBalances"
                :key="balance.currencyId"
                :balance="balance"
                @openNotEnoughModal="openNotEnoughModal"
              />
              <Pagination :size="'small'" :current="page" :page-size="pageSize" @page-change="catchPagination"
                :total="sortedBalancesGrouped.currencyGroups.length"
                v-if="sortedBalancesGrouped.currencyGroups.length > this.pageSize"/>
          </template>
          <EmptyTable v-if="!hasAllBalances"/>
        </Container>
      </template>

      <CreateWithdrawalRequestModal/>
      <PayoutModalTransfer/>
      <NewAddressModal/>
      <ChangeAddressBook/>
      <NotEnoughAdvanceBalance />
      <TwoFaRestriction />
      <TwoFaConfirmation />
    </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex';

import BalancesTopBar from "/public/components/elements/balances/BalancesTopBar";
import BalancesListHeader from "/public/components/elements/balances/BalancesListHeader";
import Balance from "/public/components/elements/balances/Balance";
import EmptyTable from "/public/components/elements/EmptyTable";
import CreateWithdrawalRequestModal from "/public/components/modals/requests/CreateWithdrawalRequestModal";
import {getCurrencyTicker, getNetworkName} from '/public/filters';
import _ from "lodash";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";
import Container from "@/public/components/common/uiKit/Container.vue";
import CurrencyImg from "../components/elements/CurrencyImg";
import BalanceCard from "../components/elements/balances/BalanceCard";
import PayoutModalTransfer from "../components/modals/payout-transfer/PayoutModalTransfer";
import btn from "../components/common/btn";
import NewAddressModal from '/public/components/modals/addressBook/NewAddressModal';
import ChangeAddressBook from '/public/components/modals/addressBook/ChangeAddressBook';
import NotEnoughAdvanceBalance from "../components/modals/business-wallet/NotEnoughAdvanceBalance";

import CommonInfoModal from "/public/components/modals/commonInfo";
import TwoFaRestriction from "../components/modals/twoFactorAuthorization/TwoFaRestriction";
import TwoFaConfirmation from "../components/modals/twoFactorAuthorization/TwoFaConfirmation";
import { isAlbertCrypto } from "@/config";

export default {
    name: 'BalancesPage',
    title: 'navigation.addresses',
    rulesAlias: 'wallets_payin',
    components: {
      TwoFaConfirmation,
      TwoFaRestriction,
        NotEnoughAdvanceBalance,
        PayoutModalTransfer,
        BalanceCard,
        CurrencyImg,
        Container,
        Preloader,
        BalancesTopBar,
        BalancesListHeader,
        Balance,
        EmptyTable,
        CreateWithdrawalRequestModal,
        Pagination,
        btn,
        NewAddressModal,
        ChangeAddressBook,
        CommonInfoModal,
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data() {
        return {
            searchText: '',
            hideEmptyBalances: false,
            currency: [],
            network: [],
            search: '',
            risk: '',
            pageSize: 10,
            page: 1,
        };
    },
    watch: {
        currency() {
            this.page = 1;
        },
        network() {
            this.page = 1;
        },
        hideEmptyBalances() {
            this.page = 1;
        },
        withdrawal: {
            handler(v) {
                if (!!v.complete) {
                    this.fetchAccounts();
                }
            },
            deep: true,
        },
    },
    created () {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].view_wallets;
        if (!isAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    computed: {
        ...mapState('accounts', {
            accounts: state => state.accounts,
            loading: state => state.loading,
        }),
        ...mapState('withdrawalRequestModal', [
            'withdrawal',
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapGetters('balances', [
            'balances',
        ]),
        ...mapState('balances', ['balancesGrouped']),
        ...mapState('app', [
            'isInit',
            'isLoading',
            'currencies',
            'networks',
        ]),
        showZeroState({ hasAllBalances, currency, network, search, hideEmptyBalances, risk }) {
          return _.isEmpty(currency) && _.isEmpty(network) && !search && !hasAllBalances && !hideEmptyBalances && !risk
        },
        isAddressBookAllowed() {
            return this.userRoleRules['address_book'].isAllowed;
        },
        account() {
            return this.accounts?.[0];
        },
        accountId() {
            return this.account?.accountId;
        },
        hasAllBalances() {
            return !!this.sortedBalancesGrouped.currencyGroups.length;
        },
        getNetworks() {
            let preparedBalances = this.hideEmptyBalances
                ? this.balances.filter(balance => {
                    return balance.balance > 0;
                }) : this.balances;

            if (!_.isEmpty(this.currency)) {
                preparedBalances = preparedBalances.filter((balance) => this.currency === getCurrencyTicker(balance.currencyId));
            }
            return preparedBalances;
        },
        getCurrencies() {
            return this.currencies.filter(({isFiat}) => !isFiat);
        },
        sortedBalancesGrouped() {
            const {
                balancesGrouped,
                hideEmptyBalances,
            } = this;

            let preparedBalances = _.cloneDeep(balancesGrouped);
            if (hideEmptyBalances) {
                for (let balancesList of preparedBalances.currencyGroups) {
                    for (let balancesListElement of balancesList.networkGroups) {
                        balancesListElement.addresses = balancesListElement.addresses.filter((i) => i.balance > 0);
                    }
                    balancesList.networkGroups = balancesList.networkGroups.filter((i) => !_.isEmpty(i.addresses));
                }
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => !_.isEmpty(i.networkGroups));
            }
            if (!_.isEmpty(this.currency) && _.isEmpty(this.network)) {
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => i.currencyId === this.currency);
            } else if (_.isEmpty(this.currency) && !_.isEmpty(this.network)) {
                for (let balancesList of preparedBalances.currencyGroups) {
                    balancesList.networkGroups = balancesList.networkGroups.filter((i) =>i.networkId === this.network);
                }
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => !_.isEmpty(i.networkGroups));
            } else if (!_.isEmpty(this.currency) && !_.isEmpty(this.network)) {
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => i.currencyId === this.currency);
                for (let balancesList of preparedBalances.currencyGroups) {
                    balancesList.networkGroups = balancesList.networkGroups.filter((i) => i.networkId === this.network);
                }
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => !_.isEmpty(i.networkGroups));
            }
            preparedBalances.currencyGroups = _.orderBy(preparedBalances.currencyGroups, ['balance'], ['desc']);
            if (this.search || this.risk || this.risk === null) {
                for (let balancesList of preparedBalances.currencyGroups) {
                    for (let balancesListElement of balancesList.networkGroups) {
                      if (this.search) {
                        balancesListElement.addresses = balancesListElement.addresses.filter((i) => i.address.includes(this.search));
                      }

                        if (this.risk || this.risk === null) {
                          balancesListElement.addresses = balancesListElement.addresses.filter((i) => i.riskLevel === this.risk);
                        }

                    }
                    balancesList.networkGroups = balancesList.networkGroups.filter((i) => !_.isEmpty(i.addresses));
                }
                preparedBalances.currencyGroups = preparedBalances.currencyGroups.filter((i) => !_.isEmpty(i.networkGroups));
            }
            return preparedBalances;
        },
        sortedBalances() {
            const {

                balances,
                hideEmptyBalances,

            } = this;


            let sortedBalances;

            const preparedBalances = hideEmptyBalances
                ? balances.filter(balance => {
                    return balance.balance > 0;
                }) : balances;

            for (const balance of preparedBalances) {
                balance.tag = getCurrencyTicker(balance.currencyId);
                balance.networkName = getNetworkName(balance.networkId);
            }
            if (_.isEmpty(this.network) && _.isEmpty(this.currency)) {
                sortedBalances = preparedBalances;
                return _.orderBy(sortedBalances, ['balance', 'tag', 'networkName'], ['desc']);
            } else if (!_.isEmpty(this.currency) && _.isEmpty(this.network)) {
                sortedBalances = preparedBalances.filter((balance) => {
                    return this.currency === balance.tag;
                });
                return _.orderBy(sortedBalances, ['balance', 'tag', 'networkName'], ['desc']);
            } else if (!_.isEmpty(this.network) && _.isEmpty(this.currency)) {
                sortedBalances = preparedBalances.filter((balance) => this.network === balance.networkName);
                return _.orderBy(sortedBalances, ['balance', 'tag', 'networkName'], ['desc']);
            } else if (!_.isEmpty(this.network) && !_.isEmpty(this.currency)) {
                sortedBalances = preparedBalances.filter((balance) => {
                    return (this.network === balance.networkName && this.currency === balance.tag);
                });
                return _.orderBy(sortedBalances, ['balance', 'tag', 'networkName'], ['desc']);
            }
            return _.orderBy(preparedBalances, ['balance', 'tag', 'networkName'], ['desc']);
        },
        paginateSortedBalances() {
          return this.sortedBalancesGrouped.currencyGroups.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
        getAccounts() {
          const {
              accounts,
          } = this;

          return accounts;
        },
        getBalances() {
          const {
              accountBalances,
          } = this;
          return accountBalances;
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.pay-in.title'),
            subtitle: this.$t('zero-states.pay-in.subtitle'),
            notifications: [
              this.$t('zero-states.pay-in.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.pay-in.actions.create-invoice.title'),
                info: this.$t('zero-states.pay-in.actions.create-invoice.info'),
                btnName: this.$t('zero-states.pay-in.actions.create-invoice.btn-name'),
                route: { name: 'invoices' },
                withoutIcon: true
              },
              {
                title: this.$t('zero-states.pay-in.actions.create-order.title'),
                info: this.$t('zero-states.pay-in.actions.create-order.info'),
                btnName: this.$t('zero-states.pay-in.actions.create-order.btn-name'),
                route: { name: 'orders' },
                withoutIcon: true
              },
              {
                title: this.$t('zero-states.pay-in.actions.api-integration.title'),
                info: this.$t('zero-states.pay-in.actions.api-integration.info'),
                btnName: this.$t('zero-states.pay-in.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
              }
            ],
            type: 'default'
          }
        }
    },
    methods: {
        ...mapActions('accounts', [
            'fetchAccounts',
        ]),
        // ...mapActions('balances', [
        //     'fetchBalances',
        // ]),
        ...mapActions('app', [
            'openModal',
        ]),
        setSearchString (value) {
            this.search = value
        },
        openNotEnoughModal () {
            this.openModal({
                name: 'NotEnoughAdvanceBalance',
                payload: {
                    advanceBalanceAmount: this.accounts[0].balance,
                },
            });
        },
        openTransferModal() {
            this.openModal({
                name: 'PayoutModalTransfer',
                payload: {withoutCurrency: true},

            });
        },
        setSearchText(searchText) {
            this.searchText = searchText.data;
        },
        setCurrency(selectedCurrency) {
            this.currency = selectedCurrency.id;
        },
        setNetwork(selectedNetwork) {
            this.network = selectedNetwork.id;
        },
        selectRisk(risk) {
            this.risk = risk.value;
        },
        toggleEmptyBalances(status) {
            this.hideEmptyBalances = status;
        },
        selectAccount(accountName) {
            if (!accountName) {
                return false;
            }
            this.getAccountBalances({accountId: accountName});
        },
        sortByBalanceValue(value) {
            this.sortBalanceValue = value;
        },
        //TODO rename method
        catchPagination(page) {
            this.page = page;
        },
    },
};

</script>

<style lang="scss" scoped>

.balances {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;

  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    margin-left: $p5;
    margin-bottom: $p30;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }

  &__btn {
    max-width: 211px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 53px;
  }
  &__list {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    border-radius: 20px;
    background: var(--secondary-color);
    padding-top: 35px;
    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);
  }


  &__footer {
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__top-bar {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }

  &__container-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__address-btn {
    width: 176px;
    height: 46px;
    background: #0059F7;
    border-radius: 12px;
    transition: all .3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background: #0028F7;
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }
}

@media screen and (max-width: 850px) {
  .balances {
    &__top-container {
      padding: 20px 20px;
    }

    &__top-bar {

    }
  }
}

@media screen and (max-width: 600px) {
  .balances {
    padding: 0 10px 10px 10px;
    &__title {
      font-size: $h3;
      padding-left: 10px;
    }

    &__paragraph {
      padding-left: 10px;
    }

    &__top-bar {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

}

@media screen and (max-width: 355px) {
  .balances {


  }

}
</style>
