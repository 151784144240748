import Vue from 'vue'

export * from './Date'

export const getTruncatedString = (txt, truncLength = 4) => {
    return (txt && txt.length > truncLength * 2) ? `${txt.slice(0, truncLength)}****${txt.slice(-truncLength)}` : txt
}

export const prettyAmountWithHideZeros = ( amount, signsAfterDot ) => {
    if (!amount) return amount

    if (signsAfterDot) {
        return parseFloat( Number(amount).toFixed(signsAfterDot) )
    } else {
        return parseFloat( Number(amount) )
    }
}

Vue.filter('getTruncatedString', getTruncatedString)
Vue.filter('prettyAmountWithHideZeros', prettyAmountWithHideZeros)