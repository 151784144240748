import collectDashboardQuery from "../../../../graphQl/queries/collectDashboard";

export const getAccountBalancesCollect = async ({dispatch, commit, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let collectDashboard;

    try {
        collectDashboard = await dispatch('app/request', {
            type: 'query',
            gql: collectDashboardQuery,
            variables: {
                organizationId,
                intervalInDays: 1000
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    const {
        data: {
            collectDashboard: collectDashboardResponse,
        },
    } = collectDashboard;

    const { history } = collectDashboardResponse
    if ( history ) {
        commit('setCollectHistory', history)
    }

    if (collectDashboardResponse.availableBalances) {
        const { addresses = [] } = collectDashboardResponse.availableBalances
        addresses.forEach(addr => addr['balance'] = addr['amount']);

        return addresses
    } else {
        return [];
    }
}
