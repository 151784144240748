<script>
import {mapState, mapGetters, mapActions} from "vuex";
  import moment from "moment";

  import Tooltip from "@/public/components/common/uiKit/tooltip/Tooltip.vue";
  import SvgSkull from "@/public/components/elements/dashboard/assets/SvgSkull.vue";
  import SvgDust from "@/public/components/elements/dashboard/assets/SvgDust.vue";
  import SvgCalendar from "@/public/components/elements/dashboard/assets/SvgCalendar.vue";

  export default {
    name: "DashboardCollection",
    components: { SvgCalendar, SvgDust, SvgSkull, Tooltip },
    watch: {
      organizationCollectDust: {
        async handler(v) {
          if (!!v) {
            await this.getAccountBalancesCollect()
          }
        },
        immediate: true
      }
    },
    computed: {
      ...mapGetters('app', ['isOrganizationOwner']),
      ...mapGetters('dashboard', ['availableToCollect', 'dustAmount', 'currentSymbol', 'dirtyAmount', 'getPercentOf']),
      ...mapGetters('app', ['getSelectedOrganization']),
      ...mapState('organizations', ['organizationCollectDust']),
      ...mapState('balances', ['collectHistory']),
      moment() {
        return moment
      },
      nextCollectingDate() {
        return moment.utc(this.getSelectedOrganization.nextCollectingAt).format('DD/MM/YY')
      },
      nextCollectingTime() {
        return moment.utc(this.getSelectedOrganization.nextCollectingAt).format('hh:mm')
      },
      daysTranslate() {
        const interval = this.getSelectedOrganization.collectingInterval
        if (interval === 1) {
          return this.$t('payments.day')
        } else if (interval > 1 && interval <= 4) {
          return this.$t('payments.short-days')
        } else if (interval > 5) {
          return this.$t('payments.days')
        }
      },
      smallAssetsHistory({ collectHistory = {} }) {
        if (!collectHistory.dates) return

        const lastPeriod = collectHistory.dates.shift(-1)

        if (!lastPeriod || !lastPeriod['small']) return

        const { small } = lastPeriod

        const payInAmountUSD =  small['payin'] ? small['payin']['amountUSD'] : 0
        const personalAmountUSD =  small['personal'] ? small['personal']['amountUSD'] : 0
        const feePayInAmountUSD =  (small['payin'] && small['payin']['fee']) ? small['payin']['fee']['amountUSD'] : 0
        const feePersonalAmountUSD = (small['personal'] && small['personal']['fee']) ? small['personal']['fee']['amountUSD'] : 0

        const total = Number(payInAmountUSD) + Number(personalAmountUSD)

        return {
          dateFrom: lastPeriod.date,
          total: total.toFixed(2),
          totalFee: (Number(feePayInAmountUSD) + Number(feePersonalAmountUSD)).toFixed(2),
          payInAmountUSD,
          personalAmountUSD,
          count: small['len'],
          feePayInAmountUSD,
          feePersonalAmountUSD
        }
      }
    },
    methods: {
      ...mapActions('balances', [
        'getAccountBalancesCollect'
      ])
    }
  }
</script>

<template>
<div class="collection">
  <div class="collection__left">
    <div>
      <div class="collection__title">{{ $t('payments.amount-next-collection') }}</div>
      <div class="collection__left-value">{{ currentSymbol }}{{ availableToCollect }}</div>
    </div>
    <div v-if="isOrganizationOwner && !!organizationCollectDust && smallAssetsHistory" class="small-assets">
      <span class="label">
        {{ $t('payments.small-asset-collections-history.amount') }}:
      </span>
      <span class="value">
       {{ smallAssetsHistory.total }}$
      </span>
      <Tooltip trigger="hover" class="small-assets__tooltip">
        <div class="tooltip">
          <div class="tooltip__title">{{ $t('payments.small-asset-collections-history.amount') }}</div>
          <div class="tooltip__date">{{ $t('payments.small-asset-collections-history.stats.date-from') }} {{ smallAssetsHistory.dateFrom | getFormatDate }}</div>
          <div class="tooltip__list">
            <div class="row">
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.count') }}:</span>
              <span class="value">{{ smallAssetsHistory.count }}</span>
            </div>
            <div class="row">
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.amount') }}:</span>
              <span class="value">{{ smallAssetsHistory.total }}$,</span>
              <span class="label">
                {{ $t('payments.small-asset-collections-history.stats.commission') }}:
              </span>
              <span class="value">
               {{ smallAssetsHistory.totalFee }}$
              </span>
            </div>
            <div class="row subrow">
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.personal-addresses') }}:</span>
              <span class="value">{{ smallAssetsHistory.personalAmountUSD }}$,</span>
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.commission') }}:</span>
              <span class="value">{{ smallAssetsHistory.feePersonalAmountUSD }}$</span>
            </div>
            <div class="row subrow">
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.payin-addresses') }}:</span>
              <span class="value">{{ smallAssetsHistory.payInAmountUSD }}$,</span>
              <span class="label">{{ $t('payments.small-asset-collections-history.stats.commission') }}:</span>
              <span class="value">{{ smallAssetsHistory.feePayInAmountUSD }}$</span>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>

<!--    <div class="collection__left-items">-->
<!--      <div class="collection__left-item">-->
<!--        <SvgSkull />-->
<!--        <div>-->
<!--          <div class="collection__title">{{ $t('payments.dirty-crypto') }}</div>-->
<!--          <div class="collection__left-item-value">{{ currentSymbol }}{{ dirtyAmount }} <span class="red-text">({{ getPercentOf(dirtyAmount, availableToCollect) }}%)</span> </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="collection__left-item">-->
<!--        <SvgDust />-->
<!--        <div>-->
<!--          <div class="collection__title">{{ $t('payments.dust') }}</div>-->
<!--          <div class="collection__left-item-value">{{ currentSymbol }}{{ dustAmount }} <span class="yellow-text">({{getPercentOf(dustAmount, availableToCollect) }}%)</span> </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="collection__right">
    <div>
      <div class="collection__next">
        <SvgCalendar />
        {{ $t('payments.next-collection') }}
      </div>
      <div class="collection__dateBlock" v-if="getSelectedOrganization.nextCollectingAt">
        <div class="collection__date">
          {{ nextCollectingDate }}
        </div>
        <div class="collection__time">
          {{ $t('payments.date-collection', { time: nextCollectingTime }) }}
<!--          в {{ nextCollectingTime }} UTC-->
        </div>
      </div>
      <div v-else class="collection__date">-</div>
    </div>
    <div class="collection__right-items">
      <div class="collection__right-divider"></div>
      <div class="collection__right-items-value">
        <div class="collection__right-item">
          <div class="collection__right-item-title">
            {{ $t('payments.frequency-collection') }}
          </div>
          <div v-if="getSelectedOrganization.collectingInterval" class="collection__right-item-value">
            {{ getSelectedOrganization.collectingInterval }} {{ daysTranslate }}
          </div>
          <div v-else class="collection__right-item-value">
            -
          </div>
        </div>
        <div class="collection__right-item">
          <div class="collection__right-item-title">
            {{ $t('payments.minimum-collection') }}
          </div>
          <div class="collection__right-item-value">
            ${{ getSelectedOrganization.collectingMinimumAmount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.collection {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 30px;
  overflow: hidden;
  &__dateBlock {
    display: flex;
    gap: 7px;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  &__title {
    color: var(--text-color-text-tertiary);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    &.small {
      font-size: 14px;
    }
  }
  &__left {
    height: 100%;
    padding: 30px;
    background: var(--bg-background-secondary, #FFF);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      &:first-child {
        margin-bottom: 20px;
      }
      &-value {
        color: var(--text-text-primary);
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        margin-top: 4px;
      }
    }
    &-value {
      color: var(--text-text-primary);
      font-size: 52px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-top: 10px;
    }

    .small-assets {
      display: flex;
      align-items: center;
      gap: 7px;

      font-size: $h4;
      font-weight: 500;

      .label {
        color: var(--text-color-text-tertiary);
      }
      .value {
        color: var(--text-text-primary);
      }

      &__tooltip {
        ::v-deep .tooltip__modal {
          min-width: 280px;
          max-width: 280px;
          align-items: start;
          right: -10px;

          .tooltip__list {
            line-height: 90%;
          }
        }

        .tooltip {
          font-family: $Gilroy;
          text-align: left;
          &__title {
            color: var(--text-text-primary, #000);
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          &__date {
            color: var(--text-color-text-tertiary);
            font-size: $h6;
            font-weight: 500;
            line-height: 100%;
            margin-top: 3px;
          }
          &__list {
            display: flex;
            flex-direction: column;
            gap: 7px;

            padding-left: 5px;

            margin-top: 10px;
            color: var(--text-text-primary, #000);
            font-family: $Gilroy;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            letter-spacing: -0.15px;

            .row {
              display: flex;
              align-items: center;
              gap: 5px;

              &.subrow {
                &::before {
                  content: '-';
                }
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px 20px;
    background: var(--bg-background-primary-purple, #744DB2);
    &-divider {
      margin-bottom: 10px;
      width: 100%;
      height: 1px;
      border-top: 1px dashed var(--border-border-input-primary-default);
      opacity: 0.4;
    }
    &-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        opacity: .4;
        color: var(--text-text-white, #FFF);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 5px;
      }
      &-value {
        color: var(--text-text-white, #FFF);
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }
    }
    &-items {
    }
  }
  &__next {
    display: flex;
    align-items: center;
    gap: 2px;
    opacity: 0.4;
    color: var(--text-text-white);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom:  5px;
  }
  &__date {
    color: var(--text-text-white, #FFF);
    font-size: 28px;
    font-weight: 500;
    line-height: 90%;
  }
  &__time {
    color: var(--text-text-white, #FFF);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }
}
.red-text {
  color: var(--OCP-0-100-error-error40, #B04444);
}
.yellow-text {
  color: var(--OCP-status-medium-medium-orange-primary, #E4A062);
}
@media screen and (max-width: 1600px) {
  .collection {
    grid-template-columns: 1fr;
  }
  .collection__left-value {
    font-size: 48px;
  }
  .collection__right {
    padding: 30px;
  }
  .collection__right-divider {
    margin: 20px 0;
  }
}
@media screen and (max-width: 900px) {
  .collection__right-divider {
    margin: 15px 0;
  }
  .collection__right-items-value {
    display: flex;
    justify-content: space-between;
  }
  .collection__title {
    font-size: 13px;
  }
  .collection__right {
    padding: 20px;
  }
  .collection__left {
    padding: 20px 20px 25px 20px;
  }
  .collection__left-items {
    margin-top: 20px;
  }
  .collection__next {
    font-size: 13px;
  }
}
</style>
