export const mutations = {
    switchTab(state, payload) {
        state.currentTabSelect = payload;
    },

    setListUser(state, payload) {
        state.listUsers = payload;
    },

    setListRoles(state, payload) {
        state.listRoles = payload;
    },

    changeDetailRole(state, payload) {
        state.detailRole = payload;
    },

    setDetailRoleId(state, payload) {
        state.detailRoleId = payload;
    },

    setCurrentRole(state, payload) {
        state.role = payload;
    },
    setSelectedRole(state, payload) {
        state.selectedRole = payload;
    },

    setChangeRoleStatus(state, payload) {
        state.changeRole = payload;
    },

    setCreateRoleStatus(state, payload) {
        state.createRole = payload;
    },

    setInviteUrl(state, payload) {
        state.inviteUrl = payload;
    },

    setOrganizationName(state, payload) {
        state.organizationName = payload;
    },
    setOrganizationImgFile(state, payload) {
        state.organizationImgFile = payload;
    },
    setOrganizationImgLink(state, payload) {
        state.organizationImgLink = payload;
    },
    setOrganizationId(state, payload) {
        state.organizationId = payload;
    },
    setOrganizationData(state, payload) {
      state.organizationData = payload;
    },
    setOrganizationKyc(state, payload) {
        state.organizationKycStatus = payload;
    },
    setOrganizationDataInputValue(state, payload) {
        state.organizationData[payload.key] = payload.value;
        console.log(state.organizationData)
    },
    setOrganizationMainCurrencyId(state, payload) {
        state.organizationData.mainCurrencyId = payload.id;
        console.log(state.organizationData)
    },
    setOrganizationMainTimezone(state, payload) {
        state.organizationData.timezone = payload;
    },
    clearAllStates: (state) => {
        state.currentTabSelect = 0;
        state.listUsers = [];
        state.listRoles = [];
        state.detailRole = false;
        state.detailRoleId = null;
        state.role = null;
        state.changeRole = false;
        state.createRole = false;
        state.inviteUrl = '';
        state.organizationName = null;
        state.organizationId = null;
    },
    setOrganizationCollectDust(state, payload) {
        state.organizationCollectDust = payload;
    }
};
