import Vue from "vue"
import moment from "moment"

export const getFormatDate = (date, format = 'DD.MM.YYYY') => {
    return moment(date).format(format)
}

export const getFormatDateTime = (date, format = 'DD.MM.YYYY HH:mm') => {
    return moment(date).format(format)
}

Vue.filter('getFormatDate', getFormatDate)
Vue.filter('getFormatDateTime', getFormatDateTime)
