<template>
<base-select
  v-bind="$attrs"
  v-model="valueModel"
  :label="label"
  :placeholder="placeholder"
  :list="list"
  :with-search="false"
  class="advanced_balances__network-select"
  @closed="search = undefined"
>
    <template #selected="data">
        <item v-bind="data" />
    </template>
    <template #info="data">
        <item v-bind="data" height="22px" />
    </template>
</base-select>
</template>

<script>
    import { mapState } from "vuex";
    import placeholder from "lodash/fp/placeholder";

    export default {
        name: "TransactionsTypesSelect",
        components: {
            Search: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Search" */ './Search'),
            BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
            Item: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Item" */ './Item'),
        },
        data() {
            return {
                search: undefined
            }
        },
        props: {
            value: {
                type: [ String, Object ],
                default: () => {}
            },
            invoiceAmount: {
                type: String,
                default: ''
            },
            invoiceCurrency: {
                type: String,
                default: ''
            },
            list: {
                type: Array,
                default: () => ([])
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState({
                currentCurrencyInfo: state => state.currency,
            }),
            valueModel: {
                get({ value }) {
                    return value
                },
                set(v) {
                    this.$emit('input', v)
                }
            }
        }
    }
</script>

<style lang="scss">
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
  }
}
.advanced_balances__network-select {
    margin: 0 !important;
    ::v-deep .sublabel {
        display: none !important;
    }
    ::v-deep  .label {
        color: var(--new-front-input-label-font-color)!important;
    }
    ::v-deep .info {
        width: 100%;
    }
}
::v-deep .advanced-balances-select-wrapper {
    .sublabel {
        display: none;
    }
}
</style>
